/*global require*/
/*eslint no-unused-vars: ["off"]*/

// Is added to current config
require.config({
    waitSeconds : 30
});

var smoothScroll;
require([
    // Below are Plone resources.
    // And smooth-scroll which is being a pain in the butt.
    'jquery',
    '/plone_portal/++theme++ugent/static/js/vendor/smooth-scroll.min.js',
    '/plone_portal/++resource++jquery-ui/jquery.ui.core.min.js',
    '/plone_portal/++resource++jquery-ui/jquery.ui.widget.min.js'
], function ($, _smoothScroll) {
    smoothScroll = _smoothScroll;
    require([
        // Moved below files to a vendor JS bundle to minimize the number
        // of requests. They are available separately too (cfr smooth-scroll
        // above.)
        //
        // '++theme++ugent/static/js/vendor/jquery-migrate.min.js',
        // '++theme++ugent/static/js/vendor/jquery.tablesorter.min.js',
        // '++theme++ugent/static/js/vendor/jquery.tocify.min.js',
        // ...
        '/plone_portal/++theme++ugent/static/js/vendor.min.js',
        '/plone_portal/++theme++ugent/js/faceted_view_extra.js',
        '/plone_portal/++theme++ugent/static/js/vendor/bootstrap-select.min.js'
    ], function () {
        // Does not include any vendor JS.
        // Includes JS from websites and ours.
        require(['/plone_portal/++theme++ugent/static/js/theme.min.js'], function () {
            return true;
        });
    });
});
